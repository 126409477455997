export const actionTypes = {
  ENABLE_FEATURE_CONTROLS: 'ENABLE_FEATURE_CONTROLS',
  ENABLE_VARIABLE_CONTROLS: 'ENABLE_VARIABLE_CONTROLS',
  ADD_CONTROLS: 'ADD_CONTROLS',
  REMOVE_CONTROLS: 'REMOVE_CONTROLS',
  NEXT_STEP: 'NEXT_STEP',
  ENABLE_NEXT: 'ENABLE_NEXT',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  TOGGLE_FEATURE: 'TOGGLE_FEATURE',
  INCREASE_CODE_REVISION: 'INCREASE_CODE_REVISION',
  SAVE_CODE: 'SAVE_CODE',
  SET_VARIABLE: 'SET_VARIABLE',
  START_EXPERIMENT: 'START_EXPERIMENT',
  TOGGLE_CODE: 'TOGGLE_CODE',
  PLAYED_GAME: 'PLAYED_GAME',
  RESET: 'RESET',
  VIEW_DOCS: 'VIEW_DOCS',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  INIT: 'INIT',
}
